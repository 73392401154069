
import { defineComponent } from "vue";
import _ from "lodash";
import FontAwesomeIcon from "@/utils/fontawesome";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import PanelLayout from "@/layouts/PanelLayout.vue";
import Widget from "@/components/template/elements/Widget.vue";
import PharmacyStore from "@/stores/pharmacy.store";
import { Pharmacy } from "@/services/pharmacy.service";
import SmallButton from "@/components/template/elements/SmallButton.vue";

export default defineComponent({
    components: {
        SmallButton,
        ContentSpacer,
        FontAwesomeIcon,
        FormInput,
        PanelLayout,
        Widget
    },
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            pharmacy: {} as Pharmacy,
            baseUrl: `${window.location.host}/rdv/`,
            isUpdatingName: false,
            isUpdatingUrl: false,

            debounceUpdateName: undefined as (undefined | Function),
            debounceUpdateUrl: undefined as (undefined | Function)
        };
    },
    watch: {
        "pharmacy.name": {
            async handler (newValue, oldValue) {
                if (oldValue === undefined) {
                    return;
                }
                this.isUpdatingName = true;
                if (this.debounceUpdateName) {
                    await this.debounceUpdateName();
                }
            }
        },
        "pharmacy.url": {
            async handler (newValue, oldValue) {
                if (oldValue === undefined) {
                    return;
                }
                this.isUpdatingUrl = true;
                if (this.debounceUpdateUrl) {
                    await this.debounceUpdateUrl();
                }
            }
        }
    },
    async mounted () {
        await PharmacyStore.loadSettings();
        this.pharmacy = this.pharmacyState.pharmacy as Pharmacy;

        this.debounceUpdateName = _.debounce(this.updateName, 1000);
        this.debounceUpdateUrl = _.debounce(this.updateUrl, 1000);
    },
    methods: {
        async copyUrl () {
            await navigator.clipboard.writeText(this.baseUrl + this.pharmacy.url);
        },
        async updateUrl (): Promise<void> {
            await PharmacyStore.update({ url: this.pharmacy.url });
            this.isUpdatingUrl = false;
        },
        async updateName (): Promise<void> {
            await PharmacyStore.update({ name: this.pharmacy.name });
            this.isUpdatingName = false;
        },
        async disconnect (): Promise<void> {
            await PharmacyStore.disconnect();
        }
    }
});
