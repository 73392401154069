<template>
    <PanelLayout title="Paramètres">
        <Widget>
            <div class="settings">
                <div class="pharmacy-name">
                    <FormInput
                        class="pharmacy-input"
                        :model-value="pharmacy.name"
                        input-id="name"
                        label="Nom de la pharmacie"
                        @update:modelValue="pharmacy.name = $event"
                    />
                    <div
                        class="icon save"
                        :class="{loading: isUpdatingName}"
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            spin
                        />
                    </div>
                </div>
                <ContentSpacer size="m" />
                <div class="pharmacy-link">
                    <FormInput
                        class="pharmacy-input"
                        :model-value="pharmacy.url"
                        input-id="url"
                        label="Adresse de la page"
                        :prepend="baseUrl"
                        @update:modelValue="pharmacy.url = $event"
                    />
                    <div
                        class="icon copy"
                        @click="copyUrl"
                    >
                        <FontAwesomeIcon icon="copy" />
                    </div>
                    <div
                        class="icon save"
                        :class="{loading: isUpdatingUrl}"
                    >
                        <FontAwesomeIcon
                            icon="spinner"
                            spin
                        />
                    </div>
                </div>
                <ContentSpacer size="m" />
                <SmallButton @click="disconnect">
                    Se déconnecter
                </SmallButton>
            </div>
        </Widget>
    </PanelLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";
import FontAwesomeIcon from "@/utils/fontawesome";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import PanelLayout from "@/layouts/PanelLayout.vue";
import Widget from "@/components/template/elements/Widget.vue";
import PharmacyStore from "@/stores/pharmacy.store";
import { Pharmacy } from "@/services/pharmacy.service";
import SmallButton from "@/components/template/elements/SmallButton.vue";

export default defineComponent({
    components: {
        SmallButton,
        ContentSpacer,
        FontAwesomeIcon,
        FormInput,
        PanelLayout,
        Widget
    },
    data () {
        return {
            pharmacyState: PharmacyStore.getState(),
            pharmacy: {} as Pharmacy,
            baseUrl: `${window.location.host}/rdv/`,
            isUpdatingName: false,
            isUpdatingUrl: false,

            debounceUpdateName: undefined as (undefined | Function),
            debounceUpdateUrl: undefined as (undefined | Function)
        };
    },
    watch: {
        "pharmacy.name": {
            async handler (newValue, oldValue) {
                if (oldValue === undefined) {
                    return;
                }
                this.isUpdatingName = true;
                if (this.debounceUpdateName) {
                    await this.debounceUpdateName();
                }
            }
        },
        "pharmacy.url": {
            async handler (newValue, oldValue) {
                if (oldValue === undefined) {
                    return;
                }
                this.isUpdatingUrl = true;
                if (this.debounceUpdateUrl) {
                    await this.debounceUpdateUrl();
                }
            }
        }
    },
    async mounted () {
        await PharmacyStore.loadSettings();
        this.pharmacy = this.pharmacyState.pharmacy as Pharmacy;

        this.debounceUpdateName = _.debounce(this.updateName, 1000);
        this.debounceUpdateUrl = _.debounce(this.updateUrl, 1000);
    },
    methods: {
        async copyUrl () {
            await navigator.clipboard.writeText(this.baseUrl + this.pharmacy.url);
        },
        async updateUrl (): Promise<void> {
            await PharmacyStore.update({ url: this.pharmacy.url });
            this.isUpdatingUrl = false;
        },
        async updateName (): Promise<void> {
            await PharmacyStore.update({ name: this.pharmacy.name });
            this.isUpdatingName = false;
        },
        async disconnect (): Promise<void> {
            await PharmacyStore.disconnect();
        }
    }
});
</script>

<style scoped lang="scss">
.settings {
    padding: 16px;
    font-size: 18px;

    .pharmacy-link,
    .pharmacy-name {
        display: flex;
        align-items: baseline;

        .pharmacy-input {
            flex: 1;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 32px;
            font-size: 32px;
            color: var(--color-text-light);
            opacity: 0.7;
            transition: ease-in-out 0.1s;
        }

        .copy {
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }

        .save {
            width: 0;
            overflow: hidden;
            transition: 0.2s ease-in-out;

            &.loading {
                width: 48px;
            }
        }
    }
}
</style>
