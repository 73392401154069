<template>
    <div class="appointment-type-form">
        <FormInput
            v-model="name"
            class="element name"
            :input-id="'name' + inputId"
            label="Nom"
            type="text"
            @submit="$emit('submit')"
        />
        <FormSpacer class="spacer" />
        <FormInput
            v-model="duration"
            class="element"
            :input-id="'duration_' + inputId"
            label="Durée"
            type="number"
            min="5"
            max="60"
            step="5"
            append="min"
            @submit="$emit('submit')"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";

export default defineComponent({
    components: {
        FormInput,
        FormSpacer
    },
    props: {
        inputId: {
            type: String,
            default: ""
        },
        modelValue: {
            type: Object,
            default: () => {
                return { };
            }
        }
    },
    emits: ["update:modelValue", "submit"],
    data () {
        return {
            name: "",
            duration: ""
        };
    },
    watch: {
        name () {
            this.emitUpdate();
        },
        duration () {
            this.emitUpdate();
        },
        modelValue: {
            immediate: true,
            handler () {
                this.name = this.modelValue.name;
                if (Number.isFinite(this.modelValue.duration)) {
                    this.duration = this.modelValue.duration?.toString();
                }
                else {
                    this.duration = "";
                }
            }
        }
    },
    methods: {
        emitUpdate () {
            this.$emit("update:modelValue", {
                name: this.name,
                duration: parseInt(this.duration)
            });
        }
    }
});
</script>

<style scoped lang="scss">
.appointment-type-form {
    display: flex;
    width: 100%;
    font-size: 18px;

    .element {
        flex-basis: 150px;

        &.name {
            flex-basis: 300px;
        }
    }

    .spacer {
        width: 64px;
    }
}
</style>
