<template>
    <Widget class="widget">
        <AppointmentTypeInput
            v-model="appointmentType"
            class="appointment-type"
            :input-id="inputId"
            @submit="submit"
        />
        <div
            v-if="appointment.incomingNumber > 0"
            class="incoming"
        >
            {{ appointment.incomingNumber }} à venir
        </div>
        <div
            v-if="inputId!=='new'"
            class="action remove"
            tabindex="0"
        >
            <FontAwesomeIcon
                icon="trash"
                class="icon"
                @click="remove"
            />
        </div>
        <div
            v-if="inputId!=='new'"
            :class="{updated: appointment.updated}"
            class="action save"
            tabindex="0"
        >
            <FontAwesomeIcon
                icon="spinner"
                spin
                class="icon"
            />
        </div>
        <div
            v-else
            class="action add"
            tabindex="0"
            @click="submit"
        >
            <FontAwesomeIcon
                icon="calendar-plus"
                class="icon"
            />
        </div>
    </Widget>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import AppointmentStore from "@/stores/appointment.store";
import { AppointmentType } from "@/services/appointment.service";
import AppointmentTypeInput from "@/components/appointment/AppointmentTypeInput.vue";
import Widget from "@/components/template/elements/Widget.vue";
import FontAwesomeIcon from "@/utils/fontawesome";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        AppointmentTypeInput,
        Widget
    },
    props: {
        appointment: {
            type: Object as PropType<AppointmentType>,
            required: true
        },
        inputId: {
            type: String,
            default: "new"
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointmentType: {} as AppointmentType,
            debounceSave: undefined as (undefined | Function)
        };
    },
    watch: {
        appointment: {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentType = this.appointment;

                if (this.appointment.updated) {
                    if (this.debounceSave) {
                        this.debounceSave();
                    }
                }
            }
        },
        appointmentType: {
            deep: true,
            handler () {
                const appointment = { ...this.appointment, ...this.appointmentType };
                AppointmentStore.updateType(appointment);
            }
        }
    },
    mounted () {
        this.debounceSave = _.debounce(this.save, 1000);
    },
    methods: {
        async submit () {
            if (this.inputId === "new") {
                await AppointmentStore.createType(this.appointmentType);
            }
        },
        async remove () {
            const appointment = { ...this.appointment, ...this.appointmentType };
            await AppointmentStore.removeType(appointment);
        },
        async save () {
            const appointment = { ...this.appointment, ...this.appointmentType };
            await AppointmentStore.saveType(appointment);
        }
    }
});
</script>

<style scoped lang="scss">
.widget {
    display: flex;
    align-items: center;
}

.appointment-type {
    padding-top: 8px;
    padding-left: 32px;
}

.incoming {
    width: 100px;
    font-size: 16px;
}

.action {
    height: 70px;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 80px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;

    &.save {
        width: 0;
        overflow: hidden;
        transition: 0.2s ease-in-out;

        &.updated {
            width: 80px;
        }
    }

    &.add {
        background: var(--color-primary);

        .icon {
            color: var(--color-text-lightest);
        }
    }

    &.remove,
    &.save {
        .icon {
            color: var(--color-text-light);
        }
    }

    &:hover .icon {
        opacity: 1;
    }

    &:focus {
        border: 1px dotted var(--color-text-light);
        outline: none;
    }

    .icon {
        font-size: 32px;
        opacity: 0.7;
        transition: ease-in-out 0.1s;
    }
}
</style>
