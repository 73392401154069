
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import AppointmentStore from "@/stores/appointment.store";
import { AppointmentType } from "@/services/appointment.service";
import AppointmentTypeInput from "@/components/appointment/AppointmentTypeInput.vue";
import Widget from "@/components/template/elements/Widget.vue";
import FontAwesomeIcon from "@/utils/fontawesome";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        AppointmentTypeInput,
        Widget
    },
    props: {
        appointment: {
            type: Object as PropType<AppointmentType>,
            required: true
        },
        inputId: {
            type: String,
            default: "new"
        }
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointmentType: {} as AppointmentType,
            debounceSave: undefined as (undefined | Function)
        };
    },
    watch: {
        appointment: {
            immediate: true,
            deep: true,
            handler () {
                this.appointmentType = this.appointment;

                if (this.appointment.updated) {
                    if (this.debounceSave) {
                        this.debounceSave();
                    }
                }
            }
        },
        appointmentType: {
            deep: true,
            handler () {
                const appointment = { ...this.appointment, ...this.appointmentType };
                AppointmentStore.updateType(appointment);
            }
        }
    },
    mounted () {
        this.debounceSave = _.debounce(this.save, 1000);
    },
    methods: {
        async submit () {
            if (this.inputId === "new") {
                await AppointmentStore.createType(this.appointmentType);
            }
        },
        async remove () {
            const appointment = { ...this.appointment, ...this.appointmentType };
            await AppointmentStore.removeType(appointment);
        },
        async save () {
            const appointment = { ...this.appointment, ...this.appointmentType };
            await AppointmentStore.saveType(appointment);
        }
    }
});
