
import { defineComponent } from "vue";
import AppointmentStore from "@/stores/appointment.store";
import { AppointmentType } from "@/services/appointment.service";
import AppointmentTypeForm from "@/components/appointment/AppointmentTypeForm.vue";

export default defineComponent({
    components: {
        AppointmentTypeForm
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointments: [] as Array<AppointmentType>
        };
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                const appointments = [] as Array<AppointmentType>;

                appointments.push({
                    id: "new",
                    name: "",
                    duration: NaN
                });

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    appointments.push(this.appointmentState.appointmentTypes[appointmentType]);
                }

                this.appointments = appointments;
            }
        }
    },
    async mounted () {
        await AppointmentStore.load();
    }
});
