<template>
    <transition-group
        name="list"
        tag="div"
    >
        <div
            v-for="appointment in appointments"
            :key="appointment.id"
            class="list"
        >
            <AppointmentTypeForm
                :input-id="appointment.id"
                :appointment="appointment"
            />
        </div>
    </transition-group>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppointmentStore from "@/stores/appointment.store";
import { AppointmentType } from "@/services/appointment.service";
import AppointmentTypeForm from "@/components/appointment/AppointmentTypeForm.vue";

export default defineComponent({
    components: {
        AppointmentTypeForm
    },
    data () {
        return {
            appointmentState: AppointmentStore.getState(),
            appointments: [] as Array<AppointmentType>
        };
    },
    watch: {
        "appointmentState.appointmentTypes": {
            immediate: true,
            deep: true,
            handler () {
                const appointments = [] as Array<AppointmentType>;

                appointments.push({
                    id: "new",
                    name: "",
                    duration: NaN
                });

                for (const appointmentType of Object.keys(this.appointmentState.appointmentTypes)) {
                    appointments.push(this.appointmentState.appointmentTypes[appointmentType]);
                }

                this.appointments = appointments;
            }
        }
    },
    async mounted () {
        await AppointmentStore.load();
    }
});
</script>

<style scoped lang="scss">
.list {
    height: 70px;
    opacity: 1;
    padding-bottom: 32px;
}

.list-enter-active,
.list-leave-active {
    transition: all .5s ease-in-out;
}
.list-enter-from,
.list-leave-to {
    height: 0;
    opacity: 0;
    padding-bottom: 0;
}
</style>
