<template>
    <PanelLayout title="Types de rendez-vous">
        <AppointmentTypeList />
    </PanelLayout>
</template>

<script lang="ts">
import AppointmentTypeList from "@/components/appointment/AppointmentTypeList.vue";
import PanelLayout from "@/layouts/PanelLayout.vue";

export default {
    components: {
        AppointmentTypeList,
        PanelLayout
    }
};
</script>

<style scoped lang="scss">

</style>
