
import AppointmentTypeList from "@/components/appointment/AppointmentTypeList.vue";
import PanelLayout from "@/layouts/PanelLayout.vue";

export default {
    components: {
        AppointmentTypeList,
        PanelLayout
    }
};
