
import { defineComponent } from "vue";
import FormInput from "@/components/template/elements/FormInput.vue";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";

export default defineComponent({
    components: {
        FormInput,
        FormSpacer
    },
    props: {
        inputId: {
            type: String,
            default: ""
        },
        modelValue: {
            type: Object,
            default: () => {
                return { };
            }
        }
    },
    emits: ["update:modelValue", "submit"],
    data () {
        return {
            name: "",
            duration: ""
        };
    },
    watch: {
        name () {
            this.emitUpdate();
        },
        duration () {
            this.emitUpdate();
        },
        modelValue: {
            immediate: true,
            handler () {
                this.name = this.modelValue.name;
                if (Number.isFinite(this.modelValue.duration)) {
                    this.duration = this.modelValue.duration?.toString();
                }
                else {
                    this.duration = "";
                }
            }
        }
    },
    methods: {
        emitUpdate () {
            this.$emit("update:modelValue", {
                name: this.name,
                duration: parseInt(this.duration)
            });
        }
    }
});
